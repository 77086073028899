import apiClient from '@u/apiClient'
import router from '@/router'

let base_errors = { deceased: {}, events: [] }

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // NEW (return promises)

  async fetchMessagesList({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`model-notification/path/${payload.path}`)
        .then(async (response) => {
          commit('setMetadata', response.data.metadata)
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  addOrUpdateModelNotification: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('data', JSON.stringify(payload))
      if (payload.new_attachments?.length > 0) {
        payload.new_attachments.forEach((file) =>
          formData.append('new_attachments[]', file, file.name)
        )
      }

      if (payload.id) {
        formData.append('_method', 'PUT')
        apiClient
          .post(`model-notification/${payload.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((reason) => {
            reject(reason)
          })
      } else {
        apiClient
          .post(`model-notification`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((reason) => {
            reject(reason)
          })
      }
    })
  },

  fetchThreadByGroupId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`model-notification/thread/${payload.groupId}`)
        .then(async (response) => {
          commit('setMetadata', response.data.metadata)
          resolve(response)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchThreadByGroupIdForAnswer({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`model-notification/create-response/${payload.groupId}/${payload.type}`)
        .then(async (response) => {
          resolve(response?.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  fetchModelNotificationById: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model-notification/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewModelNotification: ({ commit }) => {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=modelNotification`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchMessageOpenAI: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post('/ai/generate', payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchMessageTextConverted: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post('/convert-message-text', payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  //region Action emails
  async storeNewEmail({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload.model_notification))

    if (payload.model_notification.attachments?.length > 0) {
      payload.model_notification.attachments.forEach((file) =>
        formData.append('attachments[]', file)
      )
    }

    await apiClient
      .post('model-notification', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('storeNewEmail', reason)
      })
  },

  async updateModelNotification({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.model_notification))

    if (payload.model_notification.attachments?.length > 0) {
      payload.model_notification.attachments.forEach((file) =>
        formData.append('attachments[]', file)
      )
    }

    formData.append('_method', 'PUT')

    await apiClient
      .post(`/model-notification-update/${payload.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('error updateModelNotification', reason)
      })
  },

  async sendExistingEmail({ commit }, payload) {
    console.log(payload.email)
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload.email))
    if (payload.email.attachments?.length > 0) {
      payload.email.attachments.forEach((file) =>
        formData.append('attachments[]', file)
      )
    }
    console.log(formData)

    await apiClient
      .post(`model-notification/${payload.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        await console.log('sendEmail', response)
        if (response.status === 202) {
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.log('sendEmail', reason)
      })
  },

  async moveToTrash({ commit }, payload) {
    await apiClient
      .post('move-to-trash', payload)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('moveToTrash', reason)
      })
  },

  async markAsReadEmails({ commit }, payload) {
    await apiClient
      .post('mark-as-read', payload)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('markAsReadEmails', reason)
      })
  },

  async markAsUnReadEmails({ commit }, payload) {
    await apiClient
      .post('mark-as-unread', payload)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('markAsUnReadEmails', reason)
      })
  },

  async markFavoriteEmails({ commit }, payload) {
    await apiClient
      .post('mark-favorite', payload.models)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('markAsUnReadEmails', reason)
      })
  },

  async restoreEmail({ commit }, payload) {
    await apiClient
      .post('restore-email', payload.models)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('restoreEmail', reason)
      })
  },

  async changePublicStatus({ commit }, payload) {
    await apiClient
      .post('change-public-status', payload.models)
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch((reason) => {
        console.error('changePublicStatus', reason)
      })
  }
  //endregion
}
